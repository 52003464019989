import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import { H1, H3 } from "../components/common/Typography";
import EventCard from "../components/common/EventCard";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import { MEDIA_QUERIES } from "../config";
import SEO from "../components/common/SEO";
import { sortBy } from "lodash";

function GalleryPage({ data }) {
  const events = sortBy(data.allContentfulGallery.nodes, "order");

  return (
    <Layout>
      <SEO title="Gallery" />
      <main>
        <div
          css={`
            text-align: center;

            ${H1} {
              margin-bottom: 32px;
              margin-top: 64px;
            }

            ${H3} {
              margin-bottom: 72px;
            }

            ${MEDIA_QUERIES.mobile} {
              ${H1} {
                margin-top: 0;
              }

              ${H3} {
                margin-bottom: 48px;
              }
            }
          `}
        >
          <H1>Gallery</H1>
          {/* <H3>Πρόγραμμα</H3> */}
        </div>
        <SimpleReactLightbox>
          <SRLWrapper>
            <Section
              css={`
                display: grid;
                grid-template-columns: repeat(auto-fit, 326px);
                gap: 80px 23px;
                margin-bottom: 144px;

                ${MEDIA_QUERIES.mobile} {
                  justify-items: center;
                  margin-bottom: 80px;
                  grid-template-columns: 1fr;
                }
              `}
            >
              {events.map(event => (
                <EventCard
                  key={event.contentful_id}
                  event={{ ...event, type: "" }}
                />
              ))}
            </Section>
          </SRLWrapper>
        </SimpleReactLightbox>
      </main>
    </Layout>
  );
}

export const pageQuery = graphql`
  query AllGallery {
    allContentfulGallery(sort: { fields: createdAt, order: DESC }) {
      nodes {
        contentful_id
        name
        order
        image {
          contentful_id
          fixed(width: 652) {
            width
            src
          }
        }
      }
    }
  }
`;

export default GalleryPage;
